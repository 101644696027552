import React from "react";
import Navbar from '../Components/Navbar.js'
import Footer from '../Components/Footer.js'
import CommingSoon from "../Components/CommingSoon.js";

const Planary = () => {
    return ( 
        <>
            <CommingSoon />
        </>
     );
}
 
export default Planary;