import React from 'react'

const Header = () => {
  return (
    <div>
      <div className="header">
        <p className='text'> 
            <span>ICOGES 2024 | </span>
            <span>February 16 - 17</span>
        </p>
      </div>
    </div>
  )
}

export default Header
