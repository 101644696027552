import React from "react"
import pdeuimage1 from '../assets/images/pdeu-1.jpg'
import pdeuimage2 from '../assets/images/pdeu-2.jpg'
import pdeuimage3 from '../assets/images/collaborationwith.jpg'
import "../assets/css/aboutpdeu.css"

export default function AboutPDEU() {
  return (
    <div>
      <h1 className="about-heading">
        In collaboration with :
      </h1>
      <div className="pdeu-img-container">
        <img
          src={pdeuimage3}
          className="pdeu-img collaborationwith"
          alt="pdeu-img"
        />
      </div>
      <h1 className="about-heading">
        About Pandit Deendayal Energy University
      </h1>
      <div className="pdeu-img-container">
        <img
          src={pdeuimage1}
          className="pdeu-img"
          alt="pdeu-img"
        />
      </div>

      <div className="about-pdeu-text">
        <p className="about-pdeu">
          Pandit Deendayal Energy University (PDEU) was established through Gujarat Act No. 14 of 2007 on 4 April 2007 at Gandhinagar, Gujarat (State). Based on our teaching and research activities in the energy field, followed by the recommendations made by the Hon. Prime Minister Shri Narendra Modiji, during his VIII PDPU Convocation address on 21 November 2020, changed the name of PDPU to an Energy University. We are very glad to inform you that the Government of Gujarat has published an ordinance in the Government of Gujarat Gazette for renaming PDPU as Pandit Deendayal Energy University (PDEU). PDEU (PDPU) primarily aims to be known as the institution of excellence of the country in Engineering, Management, and Liberal Arts, to develop skilled graduates to work towards nation-building and offers undergraduate, postgraduate, and doctoral programs, and intensive research projects in every branch.      </p>
      </div>

      <div className="about-spt-container">
        
        <p className="about-heading">
          {/* About Department of Petroleum Engineering */}
          <h1> About</h1>
          <h1>
            School of Energy Technology & Department of Petroleum Engineering
          </h1>
        </p>
        <div className="about-pdeu-text">
          <p className="about-pdeu">
            The School of Energy Technology (SOET) was a constituent school of the PDEU. The School of Petroleum Technology (SPT) was established in 2007 with the objective of offering a high-quality academic curriculum in the field of Petroleum Engineering. Over the course of time, the organization expanded its operations into several sectors within the energy industry in order to access a wide range of energy sources. Currently, the educational institution accommodates a total of eight academic departments. The Department of Petroleum Engineering has served as the prominent department of PDEU. The department's robust collaborations with regional government entities, research institutions, and enterprises provide extensive prospects for its students, researchers, and academics to engage in high-quality research, thereby facilitating their professional development and advancement. The department offers a comprehensive curriculum for students interested in petroleum engineering, including a four-year undergraduate degree (B.Tech), a two-year Masters program (M.Tech), and Ph.D programs in several interdisciplinary research fields.

          </p>
        </div>
        <div className="pdeu-img-container">
          <img
            src={pdeuimage2}
            className="pdeu-img"
            alt="pdeu-img"
          />
        </div>
      </div>
      <div className="about-container">
        <h1 className="about-heading">ICOGES 2024</h1>
        <div className="about-para-container">
          <p>
            The International Conference on Oil & Gas for Energy Security (ICOGES) is to be organized by the Department of Petroleum Engineering, School of Energy Technology, Pandit Deendayal Energy University (PDEU) India. PDEU is a preeminent centre for energy studies and related technical disciplines.
            The two-day conference aims to facilitate the convergence of academicians, policymakers, regulators, innovators, oil and gas corporations, infrastructure firms, technology providers, industry experts, and investors. The event will serve as a platform for these diverse stakeholders to share their insights, offer stimulus, and contribute to the discourse around energy security.
            Mission, attests to India's intent to access to affordable energy sources, which is essential for a nation's economic stability and overall well-being. And expected to press on some of the crucial notes on Diversification of Energy Sources, Reliable Energy Supply, Energy Transition Challenges, Economic Impact, Geopolitical Considerations, Technological Advances, and Resilience to Disasters, Environmental Concerns, and Energy Security Strategies.

          </p>
          <p>
            The two-day conference aims to facilitate the convergence of academicians, policymakers, regulators, innovators, oil and gas corporations, infrastructure firms, technology providers, industry experts, and investors. The event will serve as a platform for these diverse stakeholders to share their insights, offer stimulus, and contribute to the discourse around energy security.
          </p>
          <p>Mission, attests to India's intent to access to affordable energy sources, which is essential for a nation's economic stability and overall well-being. And expected to press on some of the crucial notes on Diversification of Energy Sources, Reliable Energy Supply, Energy Transition Challenges, Economic Impact, Geopolitical Considerations, Technological Advances, and Resilience to Disasters, Environmental Concerns, and Energy Security Strategies. </p>
        </div>
        <div>
          <h1 className="about-subheading">Objective</h1>
          <div className="about-para-container">
            <p>The provision of immediate, reliable, and cost-effective access to oil and gas is an essential requirement for the well-being of an expanding worldwide populace. To sustain a growing population, it is imperative to move towards an energy system mostly reliant on renewable sources, while simultaneously mitigating the remaining carbon emissions. This transition is crucial for ensuring the habitability of our planet in the long run.</p>
          </div>
        </div>
        <div className="about-para-container">
          <h1 className="about-subheading">Topics</h1>
          <p><span style={{ fontWeight: 'bolder', color: '#12486B' }}>Day 1 : </span>The role of technology, regulation and data in accelerating oil and gas in the Energy mix.</p>

          <p><span style={{ fontWeight: 'bolder', color: '#12486B' }}>Day 2 : </span>Skills, concepts and experience towards an energy future, and the path ahead for exploration.</p>
        </div>
      </div>
      <div className="container">
        <h1 className="themes-heading">Theme</h1>
        <p className="selected-theme">The selected theme for the conference, <b>"Competencies and technology for a safe and long-lasting energy security,"</b> encapsulates the significant contribution made by our Oil and Gas community to the societies in which we reside and operate.</p>
      </div>
      <div className="">
        <h1 className="about-subheading">Thrust Areas</h1>
        <ul className="areas">
          <li>Drilling and Completion</li>
          <li>Production/ Reservoir/ EOR/IOR/ Integrated Reservoir Management</li>
          <li>Geosciences and Geophysics</li>
          <li>Unconventional Reservoirs (Coalbed Methane, Shale, Gas Hydrates, and Tight oil)</li>
          <li>Data Science and Artificial Intelligence</li>
          <li>Energy Transition</li>
          <li>Health, Safety and Environment</li>
          <li style={{marginBottom:'50px'}}>Multidisciplinary topics (CCUS, Pipeline/Flow Assurance, City Gas Distribution, and Refinery)</li>
        </ul>
      </div>
    </div>
  )
}
