import React from "react";
import CommingSoon from "../Components/CommingSoon";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const SeesionLead = () => {
    return ( 
        <>
           <CommingSoon />
        </>
     );
}
 
export default SeesionLead;