import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import image from '../assets/images/Chief Patron Prof. S. Sundar Manoharan Director General, PDEU Patrons Prof. Anirbid B Sircar Director-SOET, PDEU & Col. (Dr.) Rakesh Kumar Shrivastawa Registrar, PDEU (1).jpg'
import '../assets/css/Management.css'

const Management = () => {
    return (
        <>
            <Navbar />
            
            <div className="chief-patron">
                <img src={image} className="chief-image"/>
            </div>
            <Footer />
        </>
    );
}

export default Management;
