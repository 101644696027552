import React from "react";
import CommingSoon from "../Components/CommingSoon";

const PosterPresentors = () => {
    return ( 
        <>
            <CommingSoon />
        </>
     );
}
 
export default PosterPresentors;