import React from "react";
import CommingSoon from "../Components/CommingSoon";

const OralPresentors = () => {
    return ( 
        <>
            <CommingSoon />
        </>
     );
}
 
export default OralPresentors;